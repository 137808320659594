import { useLogin } from './useLogin';

interface ShopifyOAuthInfo {
    hmac?: string;
    host?: string;
    session?: string;
    shop: string;
    timestamp?: string;
}

const baseUrl = ENV.OAUTH_URL;
const { getToken } = useLogin();
export let savedOAuthInfos: ShopifyOAuthInfo | undefined;

export function useShopifyOAuth() {
    const checkHmac = async () => {
        const queryParams = new URLSearchParams(window.location.search);
        const shop = queryParams.get('shop') ?? savedOAuthInfos?.shop;
        const hmac = queryParams.get('hmac') ?? savedOAuthInfos?.hmac;
        const host = queryParams.get('host') ?? savedOAuthInfos?.host;
        const session = queryParams.get('session') ?? savedOAuthInfos?.session;
        const timestamp = queryParams.get('timestamp') ?? savedOAuthInfos?.timestamp;

        if (!shop || typeof shop !== 'string') return;
        const token = getToken();
        if (!token) {
            savedOAuthInfos = {
                hmac,
                host,
                session,
                shop,
                timestamp,
            };
            return;
        }

        const queryParamsString = `?hmac=${hmac}&host=${host}&session=${session}&shop=${shop}&timestamp=${timestamp}&jwt=${token}`;
        savedOAuthInfos = undefined;
        window.location.href = baseUrl + queryParamsString;
        await new Promise(resolve => setTimeout(resolve, 5000)); //prevent app from showing if we are redirecting
    };
    return {
        checkHmac,
    };
}
